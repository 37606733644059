/**
 * Created by preference on 2020/09/07
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** l列表  */
  getProductBarCodeList: params => {
    return API.POST('api/productBarCode/list', params)
  },
  /** 新增  */
  getProductBarCodeCreate: params => {
    return API.POST('api/productBarCode/create', params)
  },
  /** 更新  */
  getProductBarCodeUpdate: params => {
    return API.POST('api/productBarCode/update', params)
  },
}